import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import leaflet from "src/images/pages/papers/leaflet.png"
import buissnesCARDS from "src/images/pages/papers/buissness.png"
import { connect } from "react-redux"

const Papers = ({isMobile}) => (
  <Layout>
    <SEO title="Ulotki i wizytówki - Departament Reklamy" keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={2} iconBg={divisions.papers.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "Ulotki i wizytówki",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Wizytówki Premium", id: "wizytowki" },
          { title: "Cennik", id: "cennik" },
        ],
        slogan: "Papier nie zapomina",
        img: leaflet,
        texts: {
          1: {
            title: "KLASYCZNA REKLAMA W NOWOCZESNEJ ODSŁONIE",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel finibus enim, nec eleifend dolor. " +
            "Suspendisse sit amet elementum velit, nec consequat velit. In id consequat nunc.",
          },
          2: {
            title: "",
            desc: "",
          },
        },
      }}/>
      <div
        id="wizytowki"
        className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.title_block, subpageStyles.biscards_block].join(" ")}>
        <div className={subpageStyles.title}>Wizytówki Premium</div>
        <div className={subpageStyles.content}>
          <img src={buissnesCARDS}/>
          <div className={subpageStyles.text_side}>
            <div className={subpageStyles.text_block}>
              <div className={subpageStyles.subtitle}>
                ZOSTAŃ ZAPAMIĘTANY Z NAJLEPSZEJ STRONY
              </div>
              <div className={subpageStyles.subtitle}>
                UTKWIJ KOMUŚ W NAJLEPSZEJ WERSJI
              </div>
              {/*<div className={subpageStyles.text}>*/}
              {/*</div>*/}
            </div>
            <div className={subpageStyles.text_block}>
              {/*<div className={subpageStyles.subtitle}>*/}
                {/*UTKWIJ KOMUŚ W NAJLEPSZEJ WERSJI*/}
              {/*</div>*/}
              <div className={subpageStyles.text}>
                <i>Indywidualna wycena</i>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className={[subpageStyles.subpage_block, subpageStyles.quote_block].join(" ")}>
        Podaruj klientowi coś namacalnego.
      </div>
      <PriceList id="cennik" content={{
        items: [
          { title: "Ulotka", desc: "Logo, logotyp", price: "- zł", prefix: "od" },
          { title: "Katalog", desc: "Logo, logotyp", price: "- zł", prefix: "od" },
          { title: "Projekt graficzny", desc: "Logo, logotyp", price: "200 zł", prefix: "od" },
          { title: "Wizytówki", desc: "Projekt, druk", price: "300 zł", prefix: "od" },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(Papers)
